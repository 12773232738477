import React from 'react'
import styles from './phnumber.module.css'
import { FaFacebook, FaInstagram, FaPinterest, FaTiktok, FaTwitter } from 'react-icons/fa6'

const PHnumber = () => {
  return (
    <div className={styles.container}>
    
    <div className={styles.box}>
      <div className={styles.Number}>
      <p className={styles.call}>CALL US ON +91 9986793055</p>
   
       
        </div>
        {/* <div className={styles.SM_icons}>
        <FaFacebook />
                        <FaTwitter />
                        <FaInstagram />
                        <FaPinterest />
        </div> */}
        </div>
        </div>
  )
}

export default PHnumber
