import React from 'react'
import styles from './brands.module.css'
const Brands = () => {
  return (
    <div className={styles.container}>
      <h2>Trusted by more than 50+ brands</h2>
      <div className={styles.wrapper}>
                <div className={styles.slider}>
            <div className={styles.slidetrack}>
                        <div  className={styles.slide}>
                          <img className={styles.hide} src="./assets/images/left.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/akkodis.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/anandraith.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/apptio.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/asianpaints.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/capgemini.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/cycle.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/federal.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/fortis.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/fractal.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/glen.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/icai.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/ingersoll.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/jumbo.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/kauvery.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/kazi.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/kotak.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/lions.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/little.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/mossadams.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/musafir.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/oracle.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/relaince.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/simple.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/tmi.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/vision.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img src="./assets/brands/torq.png" alt="" />
                        </div>
                        <div  className={styles.slide}>
                          <img className={styles.hide} src="./assets/images/right.png" alt="" />
            </div>
         </div>
        </div>
      </div>
    </div>
  )
}

export default Brands
