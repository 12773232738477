import React from 'react'
import styles from './service.module.css'
import { SearchIcon, Voice } from '../Svg'
import Products from '../../smallComponents/products/Products'

const ServiceListing = () => {
  return (
    <>
    <div className={styles.home}>
      <h2>Seamless event rentals in Bengaluru: Projectors, Speakers, Lightings, Stages, DJ, Catering and more with Shriram Visuals</h2>
   
     {/*  <div className={styles.searchbar}>
        <span><SearchIcon /> <input type="text" placeholder='Search' /></span>
        <Voice />
      </div> */}
    </div>
    <Products />
    </>
  )
}

export default ServiceListing
